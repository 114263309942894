import API from "services/utils/api";

export const verifyBenefitCard = async ({
  policyNumber,
  divisionNumber,
  certNumber,
}) => {
  const url = "/api/v1/registration/benefit-card-validation";
  const body = {
    group: policyNumber,
    division: divisionNumber,
    certificate_number: certNumber,
  };

  try {
    let response = await API.post(url, body);
    return {
      valid: response.data.validBenefitCard,
      inUse: response.data.patientKeyInUse,
    };
  } catch (err) {
    const { isRateLimited, isIneligible } = translateHttpErrorCodes(err);
    return {
      valid: null,
      inUse: null,
      isRateLimited,
      isIneligible,
    };
  }
};

export const verifyBirthDate = async ({
  policyNumber,
  divisionNumber,
  certNumber,
  birthDate,
}) => {
  const url = "/api/v1/registration/birthdate-validation";
  const body = {
    group: policyNumber,
    division: divisionNumber,
    certificate_number: certNumber,
    birthdate: birthDate,
  };

  try {
    let response = await API.post(url, body);
    return {
      valid: response.data.validBirthdate,
    };
  } catch (err) {
    const isRateLimited = translateHttpErrorCodes(err).isRateLimited;
    return {
      valid: null,
      isRateLimited,
    };
  }
};

export const verifyEmail = async (email) => {
  const url = "/api/v1/registration/email-validation";
  const body = {
    email: email,
  };

  try {
    let response = await API.post(url, body);
    return {
      inUse: response.data.in_use,
      isAuth0User: response.data.profile_exists,
    };
  } catch (err) {
    const isRateLimited = translateHttpErrorCodes(err).isRateLimited;
    return {
      inUse: null,
      isAuth0User: null,
      isRateLimited,
    };
  }
};

export const registerUser = async (userDetails, caslConsent) => {
  const url = "/api/v1/registration/register";
  const body = {
    email: userDetails.email,
    birthdate: userDetails.birthDate,
    benefit_card: {
      group: userDetails.policyNumber,
      division: userDetails.divisionNumber,
      certificate_number: userDetails.certNumber,
    },
    casl_consent: caslConsent,
  };
  let isRegistered = false,
    isIneligible = false,
    isRateLimited = false;
  try {
    let response = await API.post(url, body);
    if (response.status === 200 || response.status === 201) {
      isRegistered = true;
    }
  } catch (err) {
    const errorTypes = translateHttpErrorCodes(err);
    isRateLimited = errorTypes.isRateLimited;
    isIneligible = errorTypes.isIneligible;
  }
  return {
    isRegistered,
    isRateLimited,
    isIneligible,
  };
};

const translateHttpErrorCodes = (error) => {
  let isRateLimited = false,
    isIneligible = false;
  if (error.response) {
    if (error.response.status === 429) isRateLimited = true;
    else if (error.response.status === 403) isIneligible = true;

    console.error(
      `Encountered [${error.response.status}] error when verifying the provided information`
    );
    console.error(error.response.data);
  } else if (error.request) {
    // The client never received a response
    console.error("Failed to get a response from PM API");
  } else {
    // Anything else
    console.error("Unknown error occurred when contacting PM API");
  }
  return { isRateLimited, isIneligible };
};

export const resendActivationEmail = async (activationEmail) => {
  const url = "/api/v1/registration/password-reset";
  const body = {
    email: activationEmail,
  };
  let isEmailSent = true,
    isRateLimited = false;
  try {
    await API.post(url, body);
  } catch (error) {
    if (error.response.status === 429) isRateLimited = true;
    isEmailSent = false;
    console.error(
      `Encountered [${error.response.status}] error when verifying the provided information`
    );
    console.error(error.response.data);
  }
  return {
    isEmailSent,
    isRateLimited,
  };
};
