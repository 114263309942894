import React from "react";
import PropTypes from "prop-types";
import { Dialog } from "empire-ui";
import modalIcon from "assets/img/compass.png";
import {
  StyledTitle,
  StyledBody,
  StyledImage,
  StyledDialogBody,
  StyledDialogFooter,
} from "../Modal.styles";
import { useTranslation } from "react-i18next";

import { BlueButton } from "components/Buttons/GenericButtons";
import { setInactivityDetected } from "components/Utils/InMemoryDataStore/InMemoryDataStore";
import { useIdleTimerContext } from "react-idle-timer";

export const INACTIVITY_TIMEOUT_CONFIRMATION_MODAL_ID =
  "inactivity-timeout-confirmation";

export const InactivityTimeoutConfirmationModal = ({ open, setOpen }) => {
  const { t } = useTranslation();
  const idleTimer = useIdleTimerContext();
  if (open) {
    idleTimer.pause();
  }
  return (
    <Dialog
      data-testid="inactivity-confirmation-modal"
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      type="default"
      width="400px"
      open={open}
      setOpen={setOpen}
      version="2"
    >
      <StyledDialogBody>
        <StyledImage src={modalIcon} alt="" />
        <StyledTitle id="modal-title">
          {t("inactivityTimeout.confirmationModal.title")}
        </StyledTitle>
        <StyledBody id="modal-description">
          {t("inactivityTimeout.confirmationModal.body")}
        </StyledBody>
      </StyledDialogBody>

      <StyledDialogFooter>
        <BlueButton
          fullWidth
          shadow={true}
          onClick={() => {
            setOpen(false);
            setInactivityDetected(false);
            idleTimer.reset();
            idleTimer.resume();
          }}
          ariaLabel={t("inactivityTimeout.confirmationModal.ariaLabel")}
          label={t("inactivityTimeout.confirmationModal.buttonLabel")}
          testId="continue-button"
        ></BlueButton>
      </StyledDialogFooter>
    </Dialog>
  );
};

InactivityTimeoutConfirmationModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};
