import React from "react";
import PropTypes from "prop-types";
import { Dialog } from "empire-ui";
import modalIcon from "assets/img/compass.png";
import {
  StyledTitle,
  StyledImage,
  StyledDialogBody,
  StyledDialogFooter,
  UnsupportedDeviceModalBody,
} from "../Modal.styles";
import { useTranslation } from "react-i18next";

import { BlueButton } from "components/Buttons/GenericButtons";

export const UNSUPPORTED_DEVICE_MODAL = "unsupported-device-modal";

export const UnsupportedDeviceModal = ({ open, setOpen }) => {
  const { t } = useTranslation();
  return (
    <Dialog
      data-testid="unsupported-device-modal"
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      type="default"
      width="400px"
      open={open}
      setOpen={setOpen}
      version="2"
    >
      <StyledDialogBody>
        <StyledImage src={modalIcon} alt="" />
        <StyledTitle id="modal-title">
          {t("unsupportedDeviceModal.title")}
        </StyledTitle>
        <UnsupportedDeviceModalBody id="modal-description">
          {t("unsupportedDeviceModal.body")}
        </UnsupportedDeviceModalBody>
      </StyledDialogBody>

      <StyledDialogFooter>
        <BlueButton
          fullWidth
          shadow={true}
          onClick={() => setOpen(false)}
          ariaLabel={t("buttons.close.ariaLabel")}
          label={t("buttons.close.label")}
          testId="close-modal-button"
        ></BlueButton>
      </StyledDialogFooter>
    </Dialog>
  );
};

UnsupportedDeviceModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};
