import Container from "components/MainContainer/Container";
import { useTranslation } from "react-i18next";
import EmailForm from "components/Forms/EmailForm/EmailForm";
import { BlueButton, WhiteButton } from "components/Buttons/GenericButtons";
import {
  FormFieldPropTypes,
  ControlPropTypes,
} from "components/RegistrationWizard/PropTypes";
import { useFormikContext } from "formik";
import { isEmpty } from "lodash";
import { PRE_EXISTING_AUTH0_ACCOUNT_MODAL_ID } from "components/Modals/PreExistingAuth0Account/Modal";
import { verifyEmail } from "services/api";
import { TERMS_AND_CONDITIONS_PAGE_ID } from "Pages/TermsAndConditions/TermsAndConditions";
import { useFormikErrorFields } from "components/Hooks/useFormikErrorFields";
import { useNavigate } from "react-router-dom";
import { EMAIL_IN_USE_MODAL_ID } from "components/Modals/EmailInUse/Modal";

export const EMAIL_PAGE_ID = "emailForm";

export const EmailView = ({ control, formFields }) => {
  const { t } = useTranslation();
  const { validateForm, setTouched, values } = useFormikContext(),
    navigate = useNavigate();

  const handleContinueButtonClick = async () => {
    const errs = await validateForm();
    if (isEmpty(errs)) {
      if (values.email !== values.confirmEmail) {
        control.setAlert("form.errors.emailMismatch");
      } else {
        control.setLoading(true);
        const { inUse, isAuth0User, isRateLimited } = await verifyEmail(
          values.email
        );
        control.setLoading(false);
        if (inUse === false && isAuth0User) {
          control.showModal(PRE_EXISTING_AUTH0_ACCOUNT_MODAL_ID);
        } else if (inUse === true && isAuth0User === true) {
          control.showModal(EMAIL_IN_USE_MODAL_ID);
        } else if (inUse === false && isAuth0User === false) {
          control.setIsNewAccount(true);
          control.goToStep(TERMS_AND_CONDITIONS_PAGE_ID);
        } else {
          if (isRateLimited) {
            control.setAlert("form.errors.rateLimitExceeded");
          } else {
            control.setAlert("form.errors.general");
          }
        }
      }
    } else {
      useFormikErrorFields(errs, setTouched);
    }
  };

  return (
    <Container control={control}>
      <EmailForm formField={formFields} />
      <BlueButton
        fullWidth={true}
        label={t("form.continueButton.label")}
        onClick={handleContinueButtonClick}
        ariaLabel={t("form.continueButton.aria-label")}
        testId="continue-to-next-page-button"
        control={control}
      />

      <WhiteButton
        fullWidth={true}
        label={t("buttons.back.label")}
        onClick={() => navigate(-1)}
        ariaLabel={t("buttons.back.aria-label")}
        testId="go-back-button"
        control={control}
      ></WhiteButton>
    </Container>
  );
};

EmailView.propTypes = {
  control: ControlPropTypes,
  formFields: FormFieldPropTypes,
};

export default EmailView;
