import { BlueButton } from "components/Buttons/GenericButtons";
import Container from "components/MainContainer/Container";
import { useTranslation } from "react-i18next";
import { ControlPropTypes } from "components/RegistrationWizard/PropTypes";

import { Title } from "components/TextComponents/TextComponents";
import {
  GreyStyledBody,
  BlackStyledBody,
  StyledContainerExistingUser,
} from "Pages/Common.styles";
import { resendActivationEmail } from "services/api";
import { THANK_YOU_SCREEN_NEW_USER_PAGE_ID } from "Pages/ThankYouScreen/NewUser";
import { useLocation } from "react-router-dom";

export const LINK_EXPIRED_PAGE_ID = "linkExpired";

export const LinkExpired = ({ control }) => {
  const { t } = useTranslation();
  const location = useLocation();

  const handleResendEmail = async () => {
    control.setLoading(true);
    const { isEmailSent, isRateLimited } = await resendActivationEmail(
      location.state.email
    );
    control.setLoading(false);
    if (isEmailSent) {
      control.goToStep(THANK_YOU_SCREEN_NEW_USER_PAGE_ID);
    } else {
      if (isRateLimited) {
        control.setAlert("form.errors.rateLimitExceeded");
      } else {
        control.setAlert("form.errors.general");
      }
    }
  };

  return (
    <Container hideTitle hideSubtitle hideSignIn control={control}>
      <StyledContainerExistingUser>
        <Title title={t("linkExpiredScreen.title")} />
        <GreyStyledBody>{t("linkExpiredScreen.body.firstLine")}</GreyStyledBody>
        <BlackStyledBody>
          {t("linkExpiredScreen.body.secondLine")}
        </BlackStyledBody>
        <BlueButton
          fullWidth={true}
          label={t("linkExpiredScreen.resendButton.label")}
          onClick={handleResendEmail}
          ariaLabel={t("linkExpiredScreen.resendButton.aria-label")}
          testId="link-expired-button"
        ></BlueButton>
      </StyledContainerExistingUser>
    </Container>
  );
};

LinkExpired.propTypes = {
  control: ControlPropTypes,
};
