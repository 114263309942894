import { StyledForm, StyledLabel } from "../Form.styles";
import { useTranslation } from "react-i18next";
import TextInput from "components/Fields/TextInput";
import { FormFieldPropTypes } from "components/RegistrationWizard/PropTypes";

const EmailForm = ({ formField }) => {
  const { t } = useTranslation();
  return (
    <StyledForm>
      <StyledLabel data-testid="email-id-label">
        {t("form.email.label")}
      </StyledLabel>
      <TextInput
        name={formField.email.name}
        placeholder={t("form.email.placeholder")}
        aria-label={t("form.email.aria-label")}
        data-testid="email-textfield"
      />

      <StyledLabel data-testid="email-id-label">
        {t("form.email.confirmEmailLabel")}
      </StyledLabel>
      <TextInput
        name={formField.confirmEmail.name}
        placeholder={t("form.email.placeholder")}
        aria-label={t("form.email.confirm-email-aria-label")}
        data-testid="email-textfield"
      />
    </StyledForm>
  );
};

EmailForm.propTypes = {
  formField: FormFieldPropTypes,
};

export default EmailForm;
