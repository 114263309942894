import { WhiteButton } from "components/Buttons/GenericButtons";
import Container from "components/MainContainer/Container";
import { HelpIcon, ColorPalette } from "empire-ui";
import SampleBenefitCardEnglish from "../../assets/img/sample_benefit_card.png";
import SampleBenefitCardFrench from "../../assets/img/sample_benefit_card_french.png";
import { useTranslation } from "react-i18next";

import { BENEFIT_CARD_PAGE_ID } from "Pages/BenefitCard/BenefitCard";
import { ControlPropTypes } from "components/RegistrationWizard/PropTypes";
import {
  StyledBody,
  StyledH6,
  StyledHR,
  StyledTopDiv,
} from "./SampleBenefitCard.styles";

export const SAMPLE_BENEFIT_CARD_PAGE_ID = "sampleBenefitCard";

export const SampleBenefitCardView = ({ control }) => {
  const { i18n, t } = useTranslation();
  const handleClick = () => control.goToStep(BENEFIT_CARD_PAGE_ID);

  return (
    <Container hideIcon hideSubtitle hideTitle control={control}>
      <StyledTopDiv>
        <HelpIcon
          height="33"
          width="33"
          fill={ColorPalette.secondaryDarkBlue[0]}
        />
        <StyledH6 data-testid="sample-benefit-card-information-header">
          {t("sampleBenefitCardInformation.header")}
        </StyledH6>
      </StyledTopDiv>
      <StyledHR />
      <StyledBody data-testid="sample-benefit-card-information-body">
        {t("sampleBenefitCardInformation.body.firstLine")}
      </StyledBody>
      <StyledBody>
        {t("sampleBenefitCardInformation.body.secondLine")}
      </StyledBody>
      <div style={{ alignItems: "center" }}>
        <img
          src={
            i18n.language === "fr"
              ? SampleBenefitCardFrench
              : SampleBenefitCardEnglish
          }
          alt={t("images.sampleBenefitCard.altText")}
          width="100%"
          height="169px"
        />
      </div>

      <WhiteButton
        fullWidth={true}
        label={t("buttons.back.label")}
        onClick={handleClick}
        ariaLabel={t("buttons.back.aria-label")}
        testId="go-back-to-the-previous-page-button"
      ></WhiteButton>
    </Container>
  );
};

SampleBenefitCardView.propTypes = {
  control: ControlPropTypes,
};
