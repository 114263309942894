import { LOADING_VIEW_PAGE_ID } from "Pages/Loading/LoadingView";
import { PASSWORD_RESET_VIEW_PAGE_ID } from "Pages/PasswordResetRedirect/PasswordResetRedirectView";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export function useValidNavigation(isValidNavigation) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isDirectNavigationAllowed =
    pathname === `/${LOADING_VIEW_PAGE_ID}` ||
    pathname === "" ||
    pathname === `/${PASSWORD_RESET_VIEW_PAGE_ID}`;
  useEffect(() => {
    if (isValidNavigation !== true && isDirectNavigationAllowed !== true) {
      navigate("/", { replace: true });
    }
  }, [isValidNavigation]);
}
