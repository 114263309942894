import { UNSUPPORTED_DEVICE_MODAL } from "components/Modals/UnsupportedDevice/Modal";
import { getPlatformSupportsLogin } from "components/Utils/Common";
import { useEffect } from "react";
import { ControlPropTypes } from "components/RegistrationWizard/PropTypes";
import styled from "styled-components";

export const PASSWORD_RESET_VIEW_PAGE_ID = "passwordResetRedirectView";

export const StyledDiv = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0%;
  background-color: #ffffff;
  z-index: 1;
`;

const PasswordResetRedirectView = ({ control }) => {
  useEffect(() => {
    if (getPlatformSupportsLogin()) {
      control.redirectToAppLogin();
    } else {
      control.showModal(UNSUPPORTED_DEVICE_MODAL);
    }
  }, []);

  return <StyledDiv />;
};

export default PasswordResetRedirectView;

PasswordResetRedirectView.propTypes = {
  control: ControlPropTypes,
};
