import registrationFormModel from "components/Forms/model";
const {
  formFields: {
    policyNumber,
    divisionNumber,
    certNumber,
    birthDate,
    email,
    confirmEmail,
  },
} = registrationFormModel;

export default {
  [policyNumber.name]: "",
  [divisionNumber.name]: "",
  [certNumber.name]: "",
  [birthDate.name]: "",
  [email.name]: "",
  [confirmEmail.name]: "",
};
