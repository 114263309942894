import styled from "styled-components";
import { useEffect, useState } from "react";

import { Form, Formik } from "formik";
import { Trans, useTranslation } from "react-i18next";
import { ValidRoutes } from "../Routes/Routes";
import registrationFormModel from "components/Forms/model";
import validationSchema from "components/Forms/validationSchema";
import {
  PreExistingAuth0AccountModal,
  PRE_EXISTING_AUTH0_ACCOUNT_MODAL_ID,
} from "components/Modals/PreExistingAuth0Account/Modal";
import {
  PreExistingUserModal,
  PRE_EXISTING_USER_MODAL_ID,
} from "components/Modals/PreExistingUser/Modal";
import { useLocation, useNavigate } from "react-router-dom";
import { LANDING_PAGE_ID } from "Pages/Landing/Landing";
import { Auth0Provider } from "@auth0/auth0-react";
import {
  PrivacyModal,
  PRIVACY_POLICY_MODAL_ID,
  TermsAndConditionsModal,
  TERMS_AND_CONDITIONS_MODAL_ID,
} from "components/Modals/TermsAndConditions/Modal";
import {
  clearFormValues,
  getCurrentAlert,
  getFormValues,
  setAlert,
  setInactivityDetected,
} from "components/Utils/InMemoryDataStore/InMemoryDataStore";
import { getMobileAppLoginLink } from "components/Utils/Common";
import {
  UnsupportedDeviceModal,
  UNSUPPORTED_DEVICE_MODAL,
} from "components/Modals/UnsupportedDevice/Modal";
import {
  InactivityTimeoutWarningModal,
  INACTIVITY_TIMEOUT_WARNING_MODAL_ID,
} from "components/Modals/InactivityTimeoutWarning/Modal";
import {
  InactivityTimeoutConfirmationModal,
  INACTIVITY_TIMEOUT_CONFIRMATION_MODAL_ID,
} from "components/Modals/InactivityTimeoutConfirmation/Modal";
import { IdleTimerProvider } from "react-idle-timer";
import {
  EmailInUseModal,
  EMAIL_IN_USE_MODAL_ID,
} from "components/Modals/EmailInUse/Modal";
import {
  IncorrectEmailModal,
  INCORRECT_EMAIL_MODAL_ID,
} from "components/Modals/IncorrectEmail/Modal";
import { LOADING_VIEW_PAGE_ID } from "Pages/Loading/LoadingView";
import { TERMS_AND_CONDITIONS_PAGE_ID } from "Pages/TermsAndConditions/TermsAndConditions";

const { formId, formFields } = registrationFormModel;

const StyledForm = styled(Form)`
  display: flex;
  justify-content: center;
`;

const RegistrationWizard = () => {
  const [currentStep, setCurrentStep] = useState(LANDING_PAGE_ID);
  const [activeModal, setActiveModal] = useState("");
  const [currentAlert, setCurrentAlert] = useState(getCurrentAlert());
  const [isLoading, setIsLoading] = useState(false);
  const [isNewAccount, setIsNewAccount] = useState(undefined);
  const currentValidationSchema = validationSchema[currentStep];
  const { pathname } = useLocation();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const currentStep = pathname.substring(1);
    setCurrentStep(currentStep);
  }, [pathname]);

  useEffect(() => {
    setAlert(currentAlert);
  }, [currentAlert]);

  const navigate = useNavigate();
  const control = {
    alert: currentAlert,
    isLoading,
    isNewAccount: isNewAccount,
    currentStep: currentStep,
    goToStep: (step, { state = {}, replace = false } = {}) => {
      navigate(`/${step}`, {
        state: { isValidNavigation: true, ...state },
        replace: replace,
      });
    },
    showModal: (modalId) => {
      setActiveModal(modalId);
    },
    setAlert: (message) => {
      setCurrentAlert({ step: currentStep, message: message });
    },
    setLoading: (loadingState) => {
      if (loadingState === true) {
        setCurrentAlert({ step: "", message: "" });
      }
      setIsLoading(loadingState);
    },
    setIsNewAccount,
    redirectToAppLogin: () => {
      window.location.href = getMobileAppLoginLink(i18n.language);
    },
  };

  const handleSubmit = (values, actions) => {
    actions.setTouched({});
    actions.setSubmitting(false);
  };

  const onRedirectCallback = () => {
    navigate(`/${TERMS_AND_CONDITIONS_PAGE_ID}`, {
      state: { isValidNavigation: true },
      replace: true,
    });
  };

  const onIdle = () => {
    setInactivityDetected(true);
    clearFormValues();
    window.location.assign(
      `https://${process.env.REACT_APP_AUTH0_DOMAIN}/v2/logout?client_id=${process.env.REACT_APP_AUTH0_CLIENT_ID}&returnTo=${window.location.origin}${process.env.PUBLIC_URL}`
    );
  };

  const privacyPolicyUrl =
    i18n.language === "fr"
      ? "https://www.empire.ca/fr/privacy-policy"
      : "https://www.empire.ca/privacy-policy";
  const privacyPracticeUrl =
    i18n.language === "fr"
      ? "https://www.empire.ca/fr/vos-renseignements-personnels-et-votre-vie-privee"
      : "https://www.empire.ca/your-personal-information-and-your-privacy";
  const legalPageUrl =
    i18n.language === "fr"
      ? "https://www.empire.ca/fr/legal"
      : "https://www.empire.ca/legal";
  const customTranslationComponents = {
    bold: <b style={{ fontFamily: "MuseoSans700Regular" }} />,
    underline: <u style={{ textDecoration: "underline" }} />,
    italics: <i style={{ fontStyle: "italic" }} />,
    break: <br />,
    linebreak: (
      <>
        <br />
        <br />
      </>
    ),
    privacyPolicyUrl: <a href={privacyPolicyUrl} />,
    privacyPracticeUrl: <a href={privacyPracticeUrl} />,
    legalPageUrl: <a href={legalPageUrl} />,
  };

  return (
    <IdleTimerProvider
      timeout={Number(process.env.REACT_APP_INACTIVITY_TIMEOUT)}
      promptTimeout={Number(process.env.REACT_APP_INACTIVITY_PROMPT_DURATION)}
      onPrompt={() => {
        setActiveModal(INACTIVITY_TIMEOUT_WARNING_MODAL_ID);
      }}
      onIdle={onIdle}
    >
      <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_DOMAIN}
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
        redirectUri={`${window.location.origin}${process.env.PUBLIC_URL}/${LOADING_VIEW_PAGE_ID}`}
        onRedirectCallback={onRedirectCallback}
      >
        <Formik
          validationSchema={currentValidationSchema}
          initialValues={getFormValues()}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          <StyledForm id={formId}>
            <ValidRoutes control={control} formFields={formFields} />
            <PreExistingUserModal
              open={activeModal === PRE_EXISTING_USER_MODAL_ID}
              setOpen={() => setActiveModal("")}
              control={control}
            />
            <IncorrectEmailModal
              open={activeModal === INCORRECT_EMAIL_MODAL_ID}
              setOpen={() => setActiveModal("")}
              control={control}
            />
            <PreExistingAuth0AccountModal
              open={activeModal === PRE_EXISTING_AUTH0_ACCOUNT_MODAL_ID}
              setOpen={() => setActiveModal("")}
              setIsNewAccount={setIsNewAccount}
            />
            <UnsupportedDeviceModal
              open={activeModal === UNSUPPORTED_DEVICE_MODAL}
              setOpen={() => setActiveModal("")}
            />
            <EmailInUseModal
              open={activeModal === EMAIL_IN_USE_MODAL_ID}
              setOpen={() => setActiveModal("")}
            />
            <InactivityTimeoutWarningModal
              open={activeModal === INACTIVITY_TIMEOUT_WARNING_MODAL_ID}
              setOpen={() => setActiveModal("")}
            />
            <InactivityTimeoutConfirmationModal
              open={activeModal === INACTIVITY_TIMEOUT_CONFIRMATION_MODAL_ID}
              setOpen={() => setActiveModal("")}
            />
            <TermsAndConditionsModal
              open={activeModal === TERMS_AND_CONDITIONS_MODAL_ID}
              setOpen={() => setActiveModal("")}
              title={t("termsAndConditionsPage.modalTitle.termsAndConditions")}
              body={
                <Trans
                  i18nKey="termsAndConditionsPage.termsAndConditions"
                  components={customTranslationComponents}
                />
              }
            />

            <PrivacyModal
              open={activeModal === PRIVACY_POLICY_MODAL_ID}
              setOpen={() => setActiveModal("")}
              title={t("termsAndConditionsPage.modalTitle.privacyPolicy")}
              body={
                <Trans
                  i18nKey="termsAndConditionsPage.privacyPolicy"
                  components={customTranslationComponents}
                />
              }
            />
          </StyledForm>
        </Formik>
      </Auth0Provider>
    </IdleTimerProvider>
  );
};

export default RegistrationWizard;
