import React from "react";
import PropTypes from "prop-types";
import { Dialog } from "empire-ui";
import modalIcon from "assets/img/compass.png";
import {
  StyledTitle,
  StyledBody,
  StyledImage,
  StyledDialogBody,
  StyledDialogFooter,
  StyledStrongBody,
  StyledLink,
} from "../Modal.styles";
import { useTranslation, Trans } from "react-i18next";

import { BlueButton } from "components/Buttons/GenericButtons";

export const EMAIL_IN_USE_MODAL_ID = "email-in-use-modal";

export const EmailInUseModal = ({ open, setOpen }) => {
  const { t } = useTranslation();
  return (
    <Dialog
      data-testid="email-in-use-modal"
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      type="default"
      width="400px"
      open={open}
      setOpen={setOpen}
      version="2"
    >
      <StyledDialogBody>
        <StyledImage src={modalIcon} alt="" />
        <StyledTitle id="modal-title">{t("emailInUseModal.title")}</StyledTitle>
        <StyledStrongBody id="modal-description">
          {t("emailInUseModal.body")}
        </StyledStrongBody>
        <StyledBody id="modal-suggestion">
          {
            <Trans
              i18nKey="emailInUseModal.suggestionBody"
              components={{
                url: (
                  <StyledLink
                    onClick={() =>
                      (window.location = "mailto:group.csu@empire.ca")
                    }
                  />
                ),
                break: <br />,
              }}
            />
          }
        </StyledBody>
      </StyledDialogBody>

      <StyledDialogFooter>
        <BlueButton
          fullWidth
          shadow={true}
          onClick={() => {
            setOpen(false);
          }}
          label={t("emailInUseModal.continue")}
          ariaLabel={t("emailInUseModal.continue")}
          testId="continue-button"
        ></BlueButton>
      </StyledDialogFooter>
    </Dialog>
  );
};

EmailInUseModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};
