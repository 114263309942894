// This module describes the overall form along with field names, error messages
// regex patterns and other reusable bits. This is exposed here so it can be leveraged
// by multiple other modules for validation, initial values, and the form structure itself.

const emailModel = {
  requiredErr: "email is required",
  requiredErrKey: "form.email.requiredErr",
  lengthErrKey: "form.email.lengthErr",
  formatRegex:
    /* eslint-disable no-useless-escape */
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  formatErrKey: "form.email.formatErr",
};

export default {
  formId: "registrationForm",
  formFields: {
    policyNumber: {
      name: "policyNumber",
      requiredErr: "Policy Number is required",
      requiredErrKey: "form.policyNumber.requiredErr",
      lengthErrKey: "form.policyNumber.lengthErr",
      formatRegex: /^[a-zA-Z0-9]{5}$/,
      formatErrKey: "form.policyNumber.formatErr",
    },
    divisionNumber: {
      name: "divisionNumber",
      requiredErr: "Division number is required",
      requiredErrKey: "form.divisionNumber.requiredErr",
      lengthErrKey: "form.divisionNumber.lengthErr",
      formatRegex: /^[a-zA-Z0-9]{3}$/,
      formatErrKey: "form.divisionNumber.formatErr",
    },
    certNumber: {
      name: "certNumber",
      requiredErr: "Certificate number is required",
      requiredErrKey: "form.certNumber.requiredErr",
      lengthErrKey: "form.certNumber.lengthErr",
      formatRegex: /^[A-Za-z0-9]{1,9}$/,
      formatErrKey: "form.certNumber.formatErr",
    },
    birthDate: {
      name: "birthDate",
      requiredErr: "Birth date is required",
      requiredErrKey: "form.birthDate.requiredErr",
    },
    email: { name: "email", ...emailModel },
    confirmEmail: { name: "confirmEmail", ...emailModel },
  },
};
