import {
  StyledTitle,
  Styledp,
  Styleda,
  Styledh4,
} from "./TextComponents.styles";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { ControlPropTypes } from "components/RegistrationWizard/PropTypes";
import { getPlatformSupportsLogin } from "components/Utils/Common";
import { UNSUPPORTED_DEVICE_MODAL } from "components/Modals/UnsupportedDevice/Modal";

export const Title = ({ title }) => {
  return (
    <StyledTitle data-testid="welcome-to-group-benefits-h1-title">
      {title}
    </StyledTitle>
  );
};

export const Subtitle = ({ subtitle }) => {
  return (
    <Styledh4 data-testid="sign-up-for-an-account-h6-title">
      {subtitle}
    </Styledh4>
  );
};

export const SignIn = ({ control }) => {
  const { t } = useTranslation();
  const handleSignIn = () => {
    if (getPlatformSupportsLogin()) {
      control.redirectToAppLogin();
    } else {
      control.showModal(UNSUPPORTED_DEVICE_MODAL);
    }
  };

  return (
    <>
      <Styledp data-testid="sign-in-hyperlink">
        {t("textComponents.preExistingAccount")}
        <Styleda onClick={handleSignIn} data-testid="sign-in-link">
          {t("textComponents.signIn")}
        </Styleda>
      </Styledp>
    </>
  );
};

Title.propTypes = {
  title: PropTypes.string.isRequired,
};
Subtitle.propTypes = {
  subtitle: PropTypes.string.isRequired,
};

SignIn.propTypes = {
  control: ControlPropTypes,
};
