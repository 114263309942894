import { BirthDateView, BIRTH_DATE_PAGE_ID } from "Pages/BirthDate/BirthDate";
import EmailView, { EMAIL_PAGE_ID } from "Pages/Email/Email";
import PageNotFoundView, {
  PAGE_NOT_FOUND_PAGE_ID,
} from "Pages/PageNotFound/PageNotFound";
import {
  SampleBenefitCardView,
  SAMPLE_BENEFIT_CARD_PAGE_ID,
} from "Pages/SampleBenefitCard/SampleBenefitCard";
import {
  BenefitCardView,
  BENEFIT_CARD_PAGE_ID,
} from "Pages/BenefitCard/BenefitCard";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import {
  FormFieldPropTypes,
  ControlPropTypes,
} from "../RegistrationWizard/PropTypes";
import { useFormikContext } from "formik";
import {
  TermsAndConditionsView,
  TERMS_AND_CONDITIONS_PAGE_ID,
} from "Pages/TermsAndConditions/TermsAndConditions";
import { LandingView } from "Pages/Landing/Landing";
import { useFormValues } from "components/Hooks/useFormValues";
import {
  ThankYouScreenExistingUser,
  THANK_YOU_SCREEN_EXISTING_USER_PAGE_ID,
} from "Pages/ThankYouScreen/ExistingUser";
import {
  ThankYouScreenNewUser,
  THANK_YOU_SCREEN_NEW_USER_PAGE_ID,
} from "Pages/ThankYouScreen/NewUser";
import { useInactivityTimer } from "components/Hooks/useInactivityTimer";
import {
  LinkExpired,
  LINK_EXPIRED_PAGE_ID,
} from "Pages/LinkExpired/LinkExpired";
import LoadingView, { LOADING_VIEW_PAGE_ID } from "Pages/Loading/LoadingView";
import { useValidNavigation } from "components/Hooks/useValidNavigation";
import { useAuth0RedirectHandler } from "components/Hooks/useAuth0RedirectHandler";
import PasswordResetRedirectView, {
  PASSWORD_RESET_VIEW_PAGE_ID,
} from "Pages/PasswordResetRedirect/PasswordResetRedirectView";

export const ValidRoutes = ({ formFields, control }) => {
  const { values } = useFormikContext(),
    location = useLocation();

  const isValidNavigation = location.state && location.state.isValidNavigation;
  useFormValues(values);
  useInactivityTimer(control);
  useValidNavigation(isValidNavigation);
  useAuth0RedirectHandler(control);

  return (
    <Routes>
      <Route path="/" element={<LandingView control={control}></LandingView>} />

      <Route
        path={`/${THANK_YOU_SCREEN_EXISTING_USER_PAGE_ID}`}
        element={
          <ThankYouScreenExistingUser
            control={control}
          ></ThankYouScreenExistingUser>
        }
      />

      <Route path={`/${LOADING_VIEW_PAGE_ID}`} element={<LoadingView />} />

      <Route
        path={`/${PASSWORD_RESET_VIEW_PAGE_ID}`}
        element={<PasswordResetRedirectView control={control} />}
      />
      <Route
        path={`/${LINK_EXPIRED_PAGE_ID}`}
        element={<LinkExpired control={control}></LinkExpired>}
      />

      <Route
        path={`/${THANK_YOU_SCREEN_NEW_USER_PAGE_ID}`}
        element={<ThankYouScreenNewUser />}
      />

      <Route
        path={`/${TERMS_AND_CONDITIONS_PAGE_ID}`}
        element={
          <TermsAndConditionsView
            control={control}
            isValidNavigation={isValidNavigation}
          ></TermsAndConditionsView>
        }
      />

      <Route
        path={`/${BENEFIT_CARD_PAGE_ID}`}
        element={
          <BenefitCardView
            control={control}
            formFields={formFields}
          ></BenefitCardView>
        }
      />
      <Route
        path={`/${SAMPLE_BENEFIT_CARD_PAGE_ID}`}
        element={
          <SampleBenefitCardView control={control} formFields={formFields} />
        }
      />
      <Route
        path={`/${PAGE_NOT_FOUND_PAGE_ID}`}
        element={<PageNotFoundView control={control} />}
      />
      <Route
        path={`/${BIRTH_DATE_PAGE_ID}`}
        element={<BirthDateView control={control} formFields={formFields} />}
      />
      <Route
        path={`/${EMAIL_PAGE_ID}`}
        element={<EmailView control={control} formFields={formFields} />}
      />
      <Route
        path="/*"
        element={<Navigate to={`/${PAGE_NOT_FOUND_PAGE_ID}`} replace />}
      />
    </Routes>
  );
};

ValidRoutes.propTypes = {
  control: ControlPropTypes.isRequired,
  formFields: FormFieldPropTypes.isRequired,
};
