import styled from "styled-components";
import { ColorPalette, Typography, DialogBody, DialogFooter } from "empire-ui";
import { Link } from "react-router-dom";

const screenWidth = window.innerWidth;
const fontStylingConstants = Typography.Constants;

export const StyledTitle = styled.h1`
  color: ${ColorPalette.grey[0]};
  font-family: ${fontStylingConstants.fontFamily(700, "Regular")};
  font-size: ${screenWidth > 400 ? "48.83px" : "39.06px"};
  letter-spacing: ${fontStylingConstants.letterSpacingXTight}px;
`;

export const StyledBody = styled.div`
  line-height: 100%;
  margin-top: 15px;
  letter-spacing: ${fontStylingConstants.letterSpacingNormal}px;
  color: #555555;
`;

export const UnsupportedDeviceModalBody = styled.div`
  line-height: 100%;
  margin-top: 15px;
  letter-spacing: ${fontStylingConstants.letterSpacingTight}px;
  color: #555555;
`;

export const StyledStrongBody = styled(StyledBody)`
  font-family: ${fontStylingConstants.fontFamily(700, "Regular")};
`;

export const StyledDialogBody = styled(DialogBody)`
  row-gap: 10px;
  overflow-y: hidden;
`;

export const StyledDialogFooter = styled(DialogFooter)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 12px;
`;

export const StyledImage = styled.img`
  margin-top: 10px;
  width: 43;
  height: 43;
`;

export const StyledLink = styled(Link)`
  color: ${ColorPalette.secondaryDarkBlue[0]};
`;
