import React from "react";
import PropTypes from "prop-types";
import { Dialog } from "empire-ui";
import modalIcon from "assets/img/compass.png";
import {
  StyledTitle,
  StyledBody,
  StyledImage,
  StyledDialogBody,
  StyledDialogFooter,
} from "../Modal.styles";
import { useTranslation } from "react-i18next";

import { BlueButton } from "components/Buttons/GenericButtons";
import { getPlatformSupportsLogin } from "components/Utils/Common";
import { ControlPropTypes } from "components/RegistrationWizard/PropTypes";

export const PRE_EXISTING_USER_MODAL_ID = "existing-user";

export const PreExistingUserModal = ({ open, setOpen, control }) => {
  const { t } = useTranslation();

  const onClick = () => {
    setOpen(false);
    control.redirectToAppLogin();
  };
  return (
    <Dialog
      data-testid="pre-existing-user-modal"
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      type="default"
      width="400px"
      open={open}
      setOpen={setOpen}
      version="2"
    >
      <StyledDialogBody>
        <StyledImage src={modalIcon} alt="" />
        <StyledTitle id="modal-title">
          {t("preExistingUserModal.title")}
        </StyledTitle>
        <StyledBody id="modal-description">
          {t("preExistingUserModal.body")}
        </StyledBody>
      </StyledDialogBody>

      <StyledDialogFooter>
        <BlueButton
          fullWidth
          shadow={true}
          disabled={!getPlatformSupportsLogin()}
          onClick={onClick}
          label={
            getPlatformSupportsLogin()
              ? t("preExistingUserModal.login")
              : t("form.errors.login.platform")
          }
          ariaLabel={t("preExistingUserModal.login")}
          testId="continue-with-my-email-button"
        ></BlueButton>
      </StyledDialogFooter>
    </Dialog>
  );
};

PreExistingUserModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  control: ControlPropTypes,
};
