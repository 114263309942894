import { BlueButton } from "components/Buttons/GenericButtons";
import Container from "components/MainContainer/Container";
import { useTranslation } from "react-i18next";
import { ControlPropTypes } from "components/RegistrationWizard/PropTypes";
import {
  BlackStyledBody,
  GreyStyledBody,
  StyledContainerExistingUser,
} from "../Common.styles";
import { Title } from "components/TextComponents/TextComponents";
import { getPlatformSupportsLogin } from "components/Utils/Common";
import { useEffect } from "react";
import { clearFormValues } from "components/Utils/InMemoryDataStore/InMemoryDataStore";

export const THANK_YOU_SCREEN_EXISTING_USER_PAGE_ID =
  "thankYouScreenExistingUser";

export const ThankYouScreenExistingUser = ({ control }) => {
  const { t } = useTranslation();

  const handleContinueButtonClick = () => {
    control.redirectToAppLogin();
  };

  useEffect(() => {
    clearFormValues();
  }, []);

  const loginButtonDisabled = !getPlatformSupportsLogin();

  return (
    <Container hideTitle hideSubtitle hideSignIn control={control}>
      <StyledContainerExistingUser>
        <Title title={t("thankyouScreen.title")} />
        <GreyStyledBody>
          {t("thankyouScreen.existingUser.body.firstLine")}
        </GreyStyledBody>
        <BlackStyledBody>
          {t("thankyouScreen.existingUser.body.secondLine")}
        </BlackStyledBody>
        <BlueButton
          fullWidth={true}
          disabled={loginButtonDisabled}
          label={
            !loginButtonDisabled
              ? t("thankyouScreen.continueButton.label")
              : t("form.errors.login.platform")
          }
          onClick={handleContinueButtonClick}
          ariaLabel={t("thankyouScreen.continueButton.aria-label")}
          testId="continue-to-login-button"
        ></BlueButton>
      </StyledContainerExistingUser>
    </Container>
  );
};

ThankYouScreenExistingUser.propTypes = {
  control: ControlPropTypes,
};
