import * as Yup from "yup";
import isEmail from "validator/lib/isEmail";

import registrationFormModel from "components/Forms/model";
import { EMAIL_PAGE_ID } from "Pages/Email/Email";
import { BENEFIT_CARD_PAGE_ID } from "Pages/BenefitCard/BenefitCard";
import { BIRTH_DATE_PAGE_ID } from "Pages/BirthDate/BirthDate";
import { TERMS_AND_CONDITIONS_PAGE_ID } from "Pages/TermsAndConditions/TermsAndConditions";
const {
  formFields: {
    policyNumber,
    divisionNumber,
    certNumber,
    birthDate,
    email,
    confirmEmail,
  },
} = registrationFormModel;
const maxAuth0EmailLength = 320;

const emailSchema = Yup.string()
  .required(email.requiredErrKey)
  .max(maxAuth0EmailLength, email.lengthErrKey)
  .test("email-format", email.formatErrKey, (value) => {
    if (value) return isEmail(value);
    return false;
  });

const validationSchema = {
  BENEFIT_CARD_PAGE_ID: Yup.object().shape({
    [policyNumber.name]: Yup.string()
      .required(policyNumber.requiredErrKey)
      .length(5, policyNumber.lengthErrKey)
      .matches(policyNumber.formatRegex, policyNumber.formatErrKey),
    [divisionNumber.name]: Yup.string()
      .required(divisionNumber.requiredErrKey)
      .length(3, divisionNumber.lengthErrKey)
      .matches(divisionNumber.formatRegex, divisionNumber.formatErrKey),
    [certNumber.name]: Yup.string()
      .required(certNumber.requiredErrKey)
      .max(9, certNumber.lengthErrKey)
      .matches(certNumber.formatRegex, certNumber.formatErrKey),
  }),
  BIRTH_DATE_PAGE_ID: Yup.object().shape({
    [birthDate.name]: Yup.string().required(birthDate.requiredErrKey),
  }),
  EMAIL_PAGE_ID: Yup.object().shape({
    [email.name]: emailSchema,
    [confirmEmail.name]: emailSchema,
  }),
};

export default {
  [BENEFIT_CARD_PAGE_ID]: validationSchema.BENEFIT_CARD_PAGE_ID,
  [BIRTH_DATE_PAGE_ID]: validationSchema.BENEFIT_CARD_PAGE_ID.concat(
    validationSchema.BIRTH_DATE_PAGE_ID
  ),
  [EMAIL_PAGE_ID]: validationSchema.BENEFIT_CARD_PAGE_ID.concat(
    validationSchema.BIRTH_DATE_PAGE_ID
  ).concat(validationSchema.EMAIL_PAGE_ID),
  [TERMS_AND_CONDITIONS_PAGE_ID]: validationSchema.BENEFIT_CARD_PAGE_ID.concat(
    validationSchema.BIRTH_DATE_PAGE_ID
  ).concat(validationSchema.EMAIL_PAGE_ID),
};
