import React from "react";
import PropTypes from "prop-types";
import { Dialog } from "empire-ui";
import modalIcon from "assets/img/compass.png";
import {
  StyledTitle,
  StyledBody,
  StyledImage,
  StyledDialogBody,
  StyledDialogFooter,
} from "../Modal.styles";
import { useTranslation } from "react-i18next";

import { BlueButton } from "components/Buttons/GenericButtons";
import { EMAIL_PAGE_ID } from "Pages/Email/Email";
import { ControlPropTypes } from "components/RegistrationWizard/PropTypes";

export const INCORRECT_EMAIL_MODAL_ID = "incorrect-email-modal";

export const IncorrectEmailModal = ({ open, setOpen, control }) => {
  const { t } = useTranslation();
  const onClick = () => {
    setOpen(false);
    control.goToStep(EMAIL_PAGE_ID);
  };
  return (
    <Dialog
      data-testid="incorrect-email-modal"
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      type="default"
      width="400px"
      open={open}
      setOpen={setOpen}
      version="2"
    >
      <StyledDialogBody>
        <StyledImage src={modalIcon} alt="" />
        <StyledTitle id="modal-title">
          {t("incorrectEmailModal.title")}
        </StyledTitle>
        <StyledBody id="modal-description">
          {t("incorrectEmailModal.body")}
        </StyledBody>
      </StyledDialogBody>

      <StyledDialogFooter>
        <BlueButton
          fullWidth
          shadow={true}
          onClick={onClick}
          label={t("incorrectEmailModal.button.label")}
          ariaLabel={t("incorrectEmailModal.button.ariaLabel")}
          testId="continue-button"
        ></BlueButton>
      </StyledDialogFooter>
    </Dialog>
  );
};

IncorrectEmailModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  control: ControlPropTypes,
};
