import React from "react";
import PropTypes from "prop-types";
import { Dialog } from "empire-ui";
import modalIcon from "assets/img/compass.png";
import {
  StyledTitle,
  StyledBody,
  StyledImage,
  StyledDialogBody,
  StyledStrongBody,
  StyledDialogFooter,
} from "../Modal.styles";
import { useTranslation } from "react-i18next";
import { useAuth0 } from "@auth0/auth0-react";
import { BlueButton, WhiteButton } from "components/Buttons/GenericButtons";
import { useFormikContext } from "formik";

export const PRE_EXISTING_AUTH0_ACCOUNT_MODAL_ID = "pre-existing-auth0-account";

export const PreExistingAuth0AccountModal = ({
  open,
  setOpen,
  setIsNewAccount,
}) => {
  const { t, i18n } = useTranslation();
  const { loginWithRedirect } = useAuth0();
  const { values } = useFormikContext();

  const handleContinueToLinkEmail = () => {
    setIsNewAccount(false);
    loginWithRedirect({
      login_hint: values.email,
      prompt: "login", // Forces us to log in EVERY time
      language: i18n.language,
    });
  };

  return (
    <Dialog
      data-testid="email-in-use-modal"
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      type="default"
      width="400px"
      open={open}
      setOpen={setOpen}
      version="2"
    >
      <StyledDialogBody>
        <StyledImage src={modalIcon} alt="" />
        <StyledTitle id="modal-title">
          {t("preExistingAuth0AccountModal.title")}
        </StyledTitle>
        <StyledBody id="modal-description">
          {t("preExistingAuth0AccountModal.body")}
        </StyledBody>
        <StyledStrongBody id="modal-suggestion">
          {t("preExistingAuth0AccountModal.suggestionBody")}
        </StyledStrongBody>
      </StyledDialogBody>

      <StyledDialogFooter>
        <BlueButton
          fullWidth
          shadow={true}
          onClick={handleContinueToLinkEmail}
          ariaLabel={t("preExistingAuth0AccountModal.continue")}
          label={t("preExistingAuth0AccountModal.continue")}
          testId="continue-with-my-email-button"
        ></BlueButton>
        <WhiteButton
          fullWidth
          shadow={true}
          onClick={() => setOpen(false)}
          ariaLabel={t("preExistingAuth0AccountModal.changeEmail")}
          label={t("preExistingAuth0AccountModal.changeEmail")}
          style={{ marginTop: "10px" }}
          testId="use-different-email-button"
        ></WhiteButton>
      </StyledDialogFooter>
    </Dialog>
  );
};

PreExistingAuth0AccountModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  setIsNewAccount: PropTypes.func.isRequired,
};
