import { useFormikContext } from "formik";
import { isEmpty } from "lodash";
import BenefitCardForm from "components/Forms/BenefitCardForm/BenefitCardForm";
import { BlueButton, WhiteButton } from "components/Buttons/GenericButtons";
import Container from "components/MainContainer/Container";
import { SAMPLE_BENEFIT_CARD_PAGE_ID } from "Pages/SampleBenefitCard/SampleBenefitCard";
import { BIRTH_DATE_PAGE_ID } from "Pages/BirthDate/BirthDate";
import { useTranslation } from "react-i18next";
import {
  ControlPropTypes,
  FormFieldPropTypes,
} from "components/RegistrationWizard/PropTypes";
import { PRE_EXISTING_USER_MODAL_ID } from "components/Modals/PreExistingUser/Modal";
import { verifyBenefitCard } from "services/api";
import { useFormikErrorFields } from "components/Hooks/useFormikErrorFields";
import { useNavigate } from "react-router-dom";

export const BENEFIT_CARD_PAGE_ID = "benefitCardForm";

export const BenefitCardView = ({ control, formFields }) => {
  const { t } = useTranslation(),
    navigate = useNavigate();
  const { validateForm, setTouched, values } = useFormikContext();
  const handleBenefitCardButtonClick = () => {
    control.goToStep(SAMPLE_BENEFIT_CARD_PAGE_ID);
  };

  const handleContinueButtonClick = async () => {
    const errs = await validateForm();
    if (isEmpty(errs)) {
      control.setLoading(true);
      const { valid, inUse, isRateLimited, isIneligible } =
        await verifyBenefitCard(values);
      control.setLoading(false);
      if (valid && inUse) {
        control.showModal(PRE_EXISTING_USER_MODAL_ID);
      } else if (valid && inUse === false) {
        control.goToStep(BIRTH_DATE_PAGE_ID);
      } else {
        if (isRateLimited) {
          control.setAlert("form.errors.rateLimitExceeded");
        } else if (valid === false) {
          control.setAlert("form.errors.invalidBenefitCard");
        } else if (isIneligible) {
          control.setAlert("form.errors.inEligibleStatus");
        } else {
          control.setAlert("form.errors.general");
        }
      }
    } else {
      useFormikErrorFields(errs, setTouched);
    }
  };

  return (
    <Container control={control}>
      <BenefitCardForm formFields={formFields} control={control} />
      <WhiteButton
        style={{ marginTop: "8px" }}
        fullWidth={true}
        label={t("form.sampleBenefitCardButton.label")}
        onClick={handleBenefitCardButtonClick}
        ariaLabel={t("form.sampleBenefitCardButton.aria-label")}
        testId="sample-benefit-card-information-button"
        control={control}
      ></WhiteButton>
      <BlueButton
        fullWidth={true}
        style={{ marginTop: "8px" }}
        label={t("form.continueButton.label")}
        onClick={handleContinueButtonClick}
        ariaLabel={t("form.continueButton.aria-label")}
        testId="continue-to-next-page-button"
        control={control}
      ></BlueButton>

      <WhiteButton
        fullWidth={true}
        label={t("buttons.back.label")}
        onClick={() => navigate(-1)}
        ariaLabel={t("buttons.back.aria-label")}
        testId="go-back-button"
        control={control}
      ></WhiteButton>
    </Container>
  );
};

BenefitCardView.propTypes = {
  control: ControlPropTypes,
  formFields: FormFieldPropTypes,
};
