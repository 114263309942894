// Gets the platform via user-agent sniffing, only concerns itself with iOS and Android
// User agent strings, all others return "unsupported"
const getPlatformFromUserAgent = () => {
  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(navigator.userAgent)) {
    return "unsupported";
  }

  if (/android/i.test(navigator.userAgent)) {
    return "android";
  }
  if (
    (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) ||
    (/Mac/.test(navigator.userAgent) &&
      navigator.maxTouchPoints &&
      navigator.maxTouchPoints > 0)
  ) {
    return "ios";
  }

  return "unsupported";
};

// Determines if the current platform supports login, useful for disabling buttons or manipulating behaviour
export const getPlatformSupportsLogin = () => {
  const platform = getPlatformFromUserAgent();
  if (platform === "android" || platform === "ios") return true;
  return false;
};

// Retrieves the link to login to the mobile app (or null if the user is not on iOS/Android)
export const getMobileAppLoginLink = (language) => {
  const platform = getPlatformFromUserAgent();
  if (platform === "android") {
    return `https://${process.env.REACT_APP_AUTH0_DOMAIN}/authorize?response_type=token&client_id=${process.env.REACT_APP_MOBILE_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_ANDROID_REDIRECT_URI}&language=${language}&audience=${process.env.REACT_APP_PMA_API_AUDIENCE}&prompt=login`;
  }
  if (platform === "ios") {
    return `https://${process.env.REACT_APP_AUTH0_DOMAIN}/authorize?response_type=token&client_id=${process.env.REACT_APP_MOBILE_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_IOS_REDIRECT_URI}&language=${language}&audience=${process.env.REACT_APP_PMA_API_AUDIENCE}&prompt=login`;
  }
  return null;
};
